<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="video">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Member Name"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value=""
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="d-flex justify-content-end align-items-center">
                  <b-button class="btn btn-info" @click="removeData('remove')"
                    >Remove Cache</b-button
                  >
                </div>
              </div> -->
            </div>
            <div
              class="table-responsive mb-2"
              v-if="member_list?.data?.length > 0"
            >
              <b-table
                striped
                bordered
                :items="member_list.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
                :tbody-tr-class="rowClass"
              >
                <!-- <template v-slot:cell(speciality_id)="row">
                  <span>{{ fetchMemSpeciality(row.item.speciality_id) }}</span>
                </template> -->
                <template v-slot:cell(practice)="row">
                  <b-button
                    class="btn btn-sm btn-primary"
                    variant="primary"
                    dark
                    text
                    target="_blank"
                    :href="
                      'https://mymedisage.com/profile-listing/edit-preview/' +
                      row.item.id
                    "
                  >
                    Create Practice Page
                  </b-button>
                </template>
                <template v-slot:cell(verified)="row">
                  <b-button
                    class="btn btn-sm"
                    variant="primary"
                    v-bind:class="{
                      'btn-secondary': row.item.is_published !== 1,
                      'btn-success': row.item.is_published === 1,
                    }"
                    @click="
                      MciModal(
                        row.item.id,
                        row.item.mobile_number,
                        row.item.is_published
                      )
                    "
                  >
                    {{
                      row.item.is_published === 1 ? "Unpublish" : "Publish Now"
                    }}
                  </b-button>
                </template>
                <template v-slot:cell(delete_profile)="row">
                  <b-button
                    class="btn btn-sm"
                    variant="primary"
                    v-bind:class="{
                      'disabled': row.item.delete_profile === 1,
                      'btn-danger': row.item.delete_profile === 1,
                    }"
                    :disabled="row.item.delete_profile === 1"
                    @click="deleteProfile(row.item.id)"
                  >
                  Delete Profile
                  </b-button>
                </template>
                <template v-slot:cell(member_tag_type)="row">
                  <span
                    class="badge text-white"
                    v-if="row.item.member_tag_type"
                    :style="{ backgroundColor: row.item.member_tag_type.color }"
                    >{{ row.item.member_tag_type.name }}</span
                  >
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(unsubscribe)="row">
                  <span
                    class="badge badge-success"
                    v-if="row.item.unsubscribe == '0'"
                    >Subscribe</span
                  >
                  <span class="badge badge-danger" v-else>Unsubscribe</span>
                </template>
                <template v-slot:cell(whatsapp_status)="row">
                  <span
                    class="badge badge-primary"
                    v-if="
                      row.item.whatsapp_status == '' ||
                      row.item.whatsapp_status == null
                    "
                    >No status</span
                  >
                  <span class="badge badge-success" v-else>{{
                    row.item.whatsapp_status
                  }}</span>
                </template>
                <template v-slot:cell(is_verified_with_mci)="row">
                  <span v-if="row.item.is_verified_with_mci == '0'"
                    >No Status</span
                  >
                  <span v-else-if="row.item.is_verified_with_mci == '1'"
                    >Verified</span
                  >
                  <span v-if="row.item.is_verified_with_mci == '2'"
                    >Not Verified</span
                  >
                  <span v-if="row.item.is_verified_with_mci == '3'"
                    >Processing</span
                  >
                  <span v-if="row.item.is_verified_with_mci == '5'"
                    >Not Valid User</span
                  >
                </template>
                <template
                  v-can="'update-video-status'"
                  v-slot:cell(demo_number)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateStatus(row.item.id)"
                    :checked="row.item.demo_number == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template
                  v-can="'update-video-status'"
                  v-slot:cell(is_review)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="toggleReviewStatus(row.item.id)"
                    :checked="row.item.is_review == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template
                  v-can="'update-video-status'"
                  v-slot:cell(ban_user)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateUserStatus(row.item.id)"
                    :checked="row.item.ban_user == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(edit)="row">
                  <router-link
                    :to="{ name: 'edit-member', params: { id: row.item.id } }"
                  >
                    <i
                      class="fa fa-edit edit-i"
                      style="color: #757d8e"
                      @click="cacheData()"
                    ></i>
                  </router-link>
                </template>
                <template v-slot:cell(name)="row">
                  <template
                    v-if="
                      (row.item.fname != null && row.item.fname != '') ||
                      (row.item.lname != null && row.item.lname != '')
                    "
                  >
                    <span class="email-word-break"
                      >{{ row.item.fname }} {{ row.item.lname }}</span
                    >
                  </template>
                  <template v-else>
                    <span>---</span>
                  </template>
                </template>
                <template v-slot:cell(email)="row">
                  <template
                    v-if="row.item.email != null && row.item.email != ''"
                  >
                    <span class="email-word-break">{{ row.item.email }}</span>
                  </template>
                  <template v-else>
                    <span>---</span>
                  </template>
                </template>
                <template v-slot:cell(mobile_number)="row">
                  <template v-if="row.item.mobile_number != null">
                    {{ row.item.mobile_number }}
                  </template>
                  <template v-else>
                    <span>---</span>
                  </template>
                </template>
                <template v-slot:cell(tag_member)="row">
                  <b-button
                    @click="openModal(row.item)"
                    class="btn btn-sm btn-primary"
                    >Tag Member</b-button
                  >
                </template>
                <template v-slot:cell(mci_verify)="row">
                  <router-link
                    :to="{
                      name: 'mci-verification',
                      query: {
                        filter: getMciStatusFilter(
                          row.item.is_verified_with_mci
                        ),
                        search: `${row.item.fname ? row.item.fname : ''} ${
                          row.item.lname ? row.item.lname : ''
                        }`,
                      },
                    }"
                    ><b-button
                      class="p-2 ml-2 btn btn-info btn-sm"
                      :disabled="row.item.mci_doc"
                    >
                      Verify now
                    </b-button></router-link
                  >
                </template>
                <template v-slot:cell(practice)="row">
                  <b-button
                    class="btn btn-sm btn-primary"
                    variant="primary"
                    dark
                    text
                    target="_blank"
                    :href="
                      'https://mymedisage.com/profile-listing/edit-preview/' +
                      row.item.id
                    "
                  >
                    Create Practice Page
                  </b-button>
                </template>
                <!-- <template v-slot:cell(short_link)="row">
                  <template v-if="row.item.short_url !== null">
                    {{ row.item.short_url }}
                  </template>
                  <b-button
                    class="btn btn-sm btn-primary"
                    variant="primary"
                    v-else
                    @click="shortlinkgenarateModel(row.item.id)"
                  >
                    Generate
                  </b-button>
                </template> -->
                <!-- <template v-slot:cell(faq)="row">
                  <router-link
                    :to="{
                      name: 'memebr-faq',
                      params: { lead_id: row.item?.is_lead?.lead_id },
                    }"
                  >
                    <b-button
                      :disabled="
                        row.item?.is_lead === 0 ||
                        row.item?.is_lead?.lead_id === null ||
                        row.item?.is_lead === undefined
                      "
                      class="btn btn-sm btn-primary"
                      variant="primary"
                    >
                      view
                    </b-button>
                  </router-link>
                </template> -->
                <!-- <template v-slot:cell(social_links)="row">
                  <router-link
                    :to="{
                      name: 'member-social-links',
                      params: { id: row.item.id },
                    }"
                  >
                    <b-button class="btn btn-sm btn-primary" variant="primary">
                      Laguages and Social Links</b-button
                    ></router-link
                  >
                </template> -->
              </b-table>
            </div>
            <template v-if="member_list.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="member_list.total"
                      :total-pages="member_list.total_pages"
                      :per-page="member_list.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
        <b-modal
          id="bvModal"
          ref="modal"
          title="Update Member Tag Type"
          @ok="handleOk"
          @cancel="closeModal"
          v-model="showModal"
          no-close-on-backdrop
          centered
          hide-header-close
          ok-title="Update"
          cancel-title="Close"
        >
          <div class="fr_main_grid">
            <div class="fr_fname">
              <span class="fr_fname_label">Member Name : </span>
              <span class="fr_fname_input content-text">{{
                form.member_name
              }}</span>
            </div>
            <div class="fr_phno">
              <span class="fr_phno_label">Mobile No : </span>
              <span class="fr_phno_input">{{ form.mobile_number }}</span>
            </div>
            <div class="fr_email">
              <span class="fr_email_label">Email : </span>
              <span class="fr_email_input"> {{ form.email }}</span>
            </div>
          </div>
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label-for="name-input" class="mt-4">
              <label>Member Tag Type <span style="color: red">*</span></label>
              <b-form-select
                id="name-input"
                v-model="form.member_tag_type_id"
                :options="getMemberTag"
                value-field="id"
                text-field="name"
                :class="{
                  'is-invalid':
                    submitted && $v.form.member_tag_type_id.$invalid,
                }"
              ></b-form-select>
              <div
                v-if="submitted && !$v.form.member_tag_type_id.required"
                class="invalid-feedback"
              >
                Member Tag Type is required.
              </div>
            </b-form-group>
          </form>
        </b-modal>
        <b-modal
          id="bvModal"
          ref="modal"
          title="Password Verification"
          @ok="handlePassword"
          @cancel="closeModal"
          v-model="showPasswordModal"
          no-close-on-backdrop
          centered
          hide-header-close
          ok-title="Submit"
          cancel-title="Close"
        >
          <div class="fr_main_grid">
            <div class="fr_fname">
              <span class="fr_fname_label">Member Name : </span>
              <span class="fr_fname_input content-text">admin</span>
            </div>
          </div>
          <form ref="form" @submit.stop.prevent="handleSubmitPassword">
            <b-form-group label-for="name-input" class="mt-4">
              <label>Password <span style="color: red">*</span></label>
              <b-form-input
                v-model="form3.password"
                type="password"
              ></b-form-input>
              <div
                v-if="submitted && !$v.form3?.password.required"
                class="invalid-feedback"
              >
                Member Tag Type is required.
              </div>
            </b-form-group>
          </form>
        </b-modal>

        <b-modal
          id="bvModal"
          v-model="showMciModal"
          title="MCI Verification"
          size="lg"
          hide-footer
          centered
        >
          <template #modal-title>Verification </template>
          <div class="d-block text-center">
            <b-container class="bv-example-row">
              <b-row>
                <b-col
                  class="d-flex justify-content-start"
                  style="font-size: 18px"
                  >MCI Verification No.<i
                    v-if="
                      verification_data?.is_mci_reg_no !== null &&
                      verification_data?.is_mci_doc !== undefined
                    "
                    class="mdi mdi-checkbox-marked-circle"
                    style="font-size: 20px; margin-left: 4px; color: green"
                  ></i
                  ><i
                    v-else
                    class="mdi mdi-close-circle"
                    style="font-size: 20px; margin-left: 4px; color: red"
                  ></i
                ></b-col>
                <b-col
                  class="d-flex justify-content-start align-items-center"
                  style="font-size: 18px"
                  >MCI Document<i
                    v-if="
                      verification_data?.is_mci_doc !== null &&
                      verification_data?.is_mci_doc !== undefined
                    "
                    class="mdi mdi-checkbox-marked-circle"
                    style="font-size: 20px; margin-left: 4px; color: green"
                  ></i>
                  <div v-else class="d-flex align-items-center">
                    <i
                      class="mdi mdi-close-circle"
                      style="font-size: 20px; margin-left: 4px; color: red"
                    ></i>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col
                  class="d-flex justify-content-start"
                  style="font-size: 18px"
                  >Member Payment<i
                    v-if="
                      verification_data?.is_payment !== null &&
                      verification_data?.is_mci_doc !== undefined
                    "
                    class="mdi mdi-checkbox-marked-circle"
                    style="font-size: 20px; margin-left: 4px; color: green"
                  ></i
                  ><i
                    v-else
                    class="mdi mdi-close-circle"
                    style="font-size: 20px; margin-left: 4px; color: red"
                  ></i
                ></b-col>
              </b-row>
            </b-container>
          </div>
          <section class="d-flex justify-content-end mt-2">
            <b-button
              variant="success"
              @click="verifyMember"
              :disabled="
                verification_data?.is_payment === null ||
                verification_data?.is_payment === undefined ||
                verification_data?.is_mci_doc === null ||
                verification_data?.is_mci_doc === undefined ||
                verification_data?.is_mci_reg_no === null ||
                verification_data?.is_mci_reg_no === undefined
              "
            >
              {{ publishState === 1 ? "Unpublish" : "Publish Now" }}
            </b-button>
          </section>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import member_mixin from "../../../mixins/ModuleJs/member-list";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, member_mixin],
  data() {
    return {
      title: "Member List",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  validations: {
    form: {
      member_tag_type_id: { required },
    },
    shortLinkForm: {
      short_link: { required },
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  created() {
    this.fetchData();
    // setInterval(() => {
    //   this.checkData();
    // }, 30 * 60 * 1000);
  },
};
</script>
